<template>
  <dl v-if="field_name">
    <dt class="text-muted">
      {{ field_name }}
    </dt>
    <dd v-if="((field_value == '') || (field_value == null))">
      -
    </dd>
    <dd v-else>
      {{ field_value }}
    </dd>
  </dl>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  props: {
    field_name: '',
    field_value: '',
  },
}
</script>
